import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import {
  IconButton,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'

export default function ThemeToggle() {
  const { toggleColorMode: toggleMode } = useColorMode()
  const ToggleIcon = useColorModeValue(SunIcon, MoonIcon)

  return (
    <IconButton
      color="brand.900"
      icon={<ToggleIcon color={'white'} />}
      variant="ghost"
      // backgroundColor="blue.100"
      _hover={{ backgroundColor: 'brand.500' }}
      aria-label="Toggle Theme"
      onClick={toggleMode}
    />
  )
}
