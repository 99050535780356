import React from 'react'
import {
  Box,
  Flex,
  Heading,
  //   Link,
  Text,
  // Grid,
  // Image,
  // List,
  // ListItem,
  // ListIcon,
  useColorModeValue,
} from '@chakra-ui/react'
// import { fontColorHeading } from './LandingSection'
import { fontSizes } from './UtilityComponents'
import { Spacer } from './UtilityComponents'
import { ServicesContent } from '../data'
export const Footer = () => {
  const backgroundColor = useColorModeValue(
    'linear(to-br, brand.400, brand.500 )',
    'linear(to-tl, brand.900, brand.900)'
  )
  const fontColor = useColorModeValue('brand.50', 'brand.1')
  const fontColorListItem = useColorModeValue('brand.100', 'gray.300')
  return (
    <>
      <Spacer />
      <Box bgGradient={backgroundColor}>
        <Box
          margin={['auto']}
          paddingY={[10, 15]}
          paddingX={[3, 5, 7]}
          maxWidth={[1400]}
          flexDirection={['column']}
          // marginTop={[10, 20]}
          color={fontColor}
          borderColor="yellow.900"
          borderWidth={0}
          borderRadius={[5]}
        >
          {/* <Heading
            paddingTop={[10]}
            letterSpacing="wide"
            fontWeight="normal"
            textTransform={'uppercase'}
            maxW={['600px']}
            fontFamily="heading"
            fontSize={fontSizes.subHeading}
          >
            Aishwarya Agarwal & Co.
          </Heading> */}
          <Flex
            justifyContent={['space-between']}
            flexWrap={['wrap']}
            marginX={['auto']}
            marginY={[10]}
          >
            <Flex flexDirection={['column']} marginX={[2]}>
              <Heading
                marginY={[5]}
                letterSpacing="wide"
                fontWeight="medium"
                textTransform={'uppercase'}
                fontFamily="heading"
                fontSize={fontSizes.listHeading}
              >
                Aishwarya Agarwal & Co.
              </Heading>
              <Text
                color={fontColorListItem}
                marginBottom={[2]}
                as="a"
                href={'/'}
                fontSize={fontSizes.listItem}
              >
                Home
              </Text>
              <Text
                color={fontColorListItem}
                marginBottom={[2]}
                as="a"
                href={'/#about-us'}
                fontSize={fontSizes.listItem}
              >
                About Us
              </Text>
              <Text
                color={fontColorListItem}
                marginBottom={[2]}
                as="a"
                href={'/services'}
                fontSize={fontSizes.listItem}
              >
                Services
              </Text>
              <Text
                color={fontColorListItem}
                marginBottom={[2]}
                as="a"
                href={'/insights'}
                fontSize={fontSizes.listItem}
              >
                Insights
              </Text>
              <Text
                color={fontColorListItem}
                marginBottom={[2]}
                as="a"
                href={'/#contact-us'}
                fontSize={fontSizes.listItem}
              >
                Contact Us{' '}
              </Text>

              {/* <Text
                  color={fontColorListItem}
                  marginBottom={[2]}
                  as="a"
                  href={
                   ''
                  }
                  fontSize={fontSizes.listItem}
                  
                >
                  Contac
                </Text> */}
            </Flex>
            <Flex
              maxWidth={[300]}
              flexDirection={['column']}
              marginX={[2]}
            >
              <Heading
                marginY={[5]}
                letterSpacing="wide"
                fontWeight="medium"
                textTransform={'uppercase'}
                fontFamily="heading"
                fontSize={fontSizes.listHeading}
              >
                Services
              </Heading>
              {ServicesContent.filter((j, index) => index <= 9).map(
                (service, index) => (
                  <Text
                    color={fontColorListItem}
                    marginBottom={[2]}
                    as="a"
                    href={
                      '/services/#' +
                      service.name.split(' ').join('-')
                    }
                    fontSize={fontSizes.listItem}
                    key={index}
                  >
                    {service.name}
                  </Text>
                )
              )}
            </Flex>
            <Flex
              maxWidth={[300]}
              flexDirection={['column']}
              marginX={[2]}
            >
              <Heading
                marginY={[5]}
                letterSpacing="wide"
                fontWeight="medium"
                textTransform={'uppercase'}
                fontFamily="heading"
                fontSize={fontSizes.listHeading}
              >
                Additional Services
              </Heading>
              {ServicesContent.filter((j, index) => index > 9).map(
                (service, index) => (
                  <Text
                    color={fontColorListItem}
                    marginBottom={[2]}
                    as="a"
                    href={
                      '/services/#' +
                      service.name.split(' ').join('-')
                    }
                    fontSize={fontSizes.listItem}
                    key={index}
                  >
                    {service.name}
                  </Text>
                )
              )}
            </Flex>
            <Flex flexDirection={['column']} marginX={[2]}>
              <Heading
                marginY={[5]}
                letterSpacing="wide"
                fontWeight="medium"
                textTransform={'uppercase'}
                fontFamily="heading"
                fontSize={fontSizes.listHeading}
              >
                Reach us at
              </Heading>
              <Text
                color={fontColorListItem}
                marginBottom={[2]}
                as="a"
                href={'mailto:ca.aishwaryatulsyan@gmail.com'}
                fontSize={fontSizes.listItem}
                // key={index}
              >
                Email
              </Text>
              <Text
                color={fontColorListItem}
                marginBottom={[2]}
                as="a"
                href={'tel:6281482842'}
                fontSize={fontSizes.listItem}
                // key={index}
              >
                Phone
              </Text>
              <Text
                color={fontColorListItem}
                marginBottom={[2]}
                target="_blank"
                as="a"
                href={'https://wa.me/916281482842'}
                fontSize={fontSizes.listItem}
                // key={index}
              >
                Whatsapp
              </Text>
              {/* <Text
                color={fontColorListItem}
                marginBottom={[2]}
                target="_blank"
                as="a"
                href={
                  'https://instagram.com/ca_aishwarya_tulsyan?igshid=1dg98ye9juogg'
                }
                fontSize={fontSizes.listItem}
                // key={index}
              >
                Instagram
              </Text> */}
              <Text
                color={fontColorListItem}
                target="_blank"
                marginBottom={[2]}
                as="a"
                href={'https://twitter.com/Aishwar44013063?s=08'}
                fontSize={fontSizes.listItem}
                // key={index}
              >
                Twitter
              </Text>
              {/* <Text
                color={fontColorListItem}
                marginBottom={[2]}
                target="_blank"

                as="a"
                href={''}
                fontSize={fontSizes.listItem}
                // key={index}
              >
                Facebook
              </Text> */}
            </Flex>
            {/* <Flex flexDirection={['column']}>
              <Heading>Services</Heading>
              <Text>bla</Text>
              <Text>bla</Text>
              <Text>bla</Text>
              <Text>bla</Text>
            </Flex>
            <Flex flexDirection={['column']}>
              <Heading>Services</Heading>
              <Text>bla</Text>
              <Text>bla</Text>
              <Text>bla</Text>
              <Text>bla</Text>
            </Flex> */}
          </Flex>
        </Box>
      </Box>
    </>
  )
}
