import React, { useState, useEffect } from 'react'
// import { Image as GatsbyImg } from 'gatsby'
import {
  Box,
  Flex,
  Heading,
  //   Link,
  Text,
  // Grid,
  Image,
  UnorderedList as List,
  ListItem,
  // ListIcon,
  Button,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react'
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp'
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown'
// import { BiPhoneCall } from '@react-icons/all-files/bi/BiPhoneCall'
import { IoLogoWhatsapp } from '@react-icons/all-files/io/IoLogoWhatsapp'
// import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail'
import { Collapse, useDisclosure } from '@chakra-ui/react'
// import LandingPageImage from '../images/LandingImg.jpg'
// import LandingPageImage from '../images/Startup.jpg'

export const fontSizes = {
  landingHeading: ['4xl', '6xl', '6xl', '7xl'],
  landingSubHeading: ['xl', '2xl', 'xl', '3xl'],
  heading: ['3xl', '5xl', '6xl', '6xl'],
  subHeading: ['xl', '2xl', '2xl', '3xl'],
  sectionDescription: ['lg', 'xl', 'xl', '2xl'],
  cardHeading: ['lg', '2xl', '2xl', '3xl'],
  cardDescription: ['sm', 'md', 'md', 'md'],
  listHeading: ['md', 'ld', 'lg', 'lg'],
  listItem: ['xs', 'sm', 'sm', 'sm'],
  helperText: ['sm'],
  actionButtonIcons: ['xl', '2xl', '2xl', '3xl'],
  actionButtonIconsLandingSection: ['xl', '2xl', '4xl', '4xl'],
}

export const ButtonWithIcon = ({ iconName, bgColor, href }) => {
  return (
    <Button
      as={'a'}
      rel={'noopener'}
      target={'_blank'}
      href={href}
      // display={fromIndexPage ? 'block' : 'none'}
      // onClick={() => handleToggleNoOfLines()}
      // fontSize={['sm']}
      fontWeight={['normal']}
      // cursor={'pointer'}
      alignSelf={'flex-end'}
      width={'fit-content'}
      // marginRight={[2]}
      // marginRight={[2]}
      height={'fit-content'}
      padding={[2.5, 2, 2, 1.5]}
      // paddingY={[1.5]}
      borderRadius={[50]}
      backgroundColor={bgColor}
      justifyContent={'center'}
      textAlign={'center'}
      boxShadow={['md']}
    >
      <Icon
        as={iconName}
        fontSize={fontSizes.actionButtonIcons}
        color={'white'}
        cursor={'pointer'}
        padding={[0, 0, 0, 0.5]}
        // onClick={() => onToggle()}
      />
    </Button>
  )
}

export const ServicesCard = ({
  fromIndexPage = false,
  name = '',
  description = '',
  types = {},
  imageName,
  readMore = '',
  index,
}) => {
  const gradientColor = useColorModeValue(
    'linear(to-t, brand.900, transparent)',
    'linear(to-t, brand.900, transparent)'
  )
  const backgroundColor = useColorModeValue(
    'linear(to-tl, brand.50, brand.50)',
    'linear(to-br, brand.700, brand.700)'
  )
  const fontReadMoreColor = useColorModeValue(
    'purple.800',
    'brand.50'
  )
  const fontColor = useColorModeValue('brand.900', 'brand.1')
  const fontHeadingColor = useColorModeValue('brand.1', 'brand.1')
  const NO_OF_LINES = 4
  const [noOfLines, setNoOfLines] = useState(NO_OF_LINES)
  const handleToggleNoOfLines = () => {
    if (noOfLines === NO_OF_LINES) {
      setNoOfLines(null)
    } else {
      setNoOfLines(NO_OF_LINES)
    }
  }

  const whatsappMessage =
    'https://wa.me/916281482842/?text=' +
    encodeURI(
      'Hello, I would like to enquire about ' + String(name) + '.'
    )
  const imgSrc = `../images/` + imageName

  const RenderTypes = ({ types }) => {
    const { multiType, typesList } = types
    if (multiType === true) {
      return (
        <Flex
          flexDirection="row"
          flexWrap="wrap"
          justifyContent={'space-between'}
        >
          {typesList
            .filter((item, index) =>
              noOfLines === NO_OF_LINES
                ? index === 0
                : index <= typesList.length
            )
            .map((type, index) => (
              <Box key={index} marginRight={4}>
                <Heading
                  marginY={3}
                  textTransform={['capitalize']}
                  fontSize={fontSizes.cardDescription}
                >
                  {type.name || ' '}
                </Heading>
                <List marginRight={[0]}>
                  {' '}
                  {type.list
                    .filter((item, index) =>
                      noOfLines === NO_OF_LINES
                        ? index <= 3
                        : index <= type.list.length
                    )
                    .map((listItem, index) => (
                      <ListItem
                        fontSize={fontSizes.listItem}
                        key={index}
                      >
                        {listItem}
                      </ListItem>
                    ))}
                </List>
              </Box>
            ))}
        </Flex>
      )
    }
    if (multiType === false) {
      return (
        <Box marginRight={2}>
          <Heading
            marginY={3}
            textTransform={['capitalize']}
            fontSize={fontSizes.cardDescription}
          >
            {''}
          </Heading>
          <List marginRight={[4]}>
            {typesList
              .filter((item, index) =>
                noOfLines === NO_OF_LINES
                  ? index <= 3
                  : index <= typesList.length
              )
              .map((listItem, index) => (
                <ListItem fontSize={fontSizes.listItem} key={index}>
                  {listItem}
                </ListItem>
              ))}
          </List>
        </Box>
      )
    }
  }
  useEffect(() => {
    if (fromIndexPage !== true) {
      setNoOfLines(null)
    }
  }, [])

  return (
    <Box
      maxW={['100%', '100%', '48.5%', '48.5%']}
      marginY={[5, 5, 7, 7]}
      marginLeft={0}
      bgGradient={backgroundColor}
      padding={[0]}
      color={fontColor}
      borderRadius={[5]}
      boxShadow={'lg'}
      id={name.split(' ').join('-')}
    >
      <Flex flexDirection="column">
        <Box position={'relative'}>
          <Image
            borderTopLeftRadius={[5]}
            borderTopRightRadius={[5]}
            width={['100%']}
            height={[225]}
            zIndex={10}
            src={imageName}
            alt={'Image for ' + name}
            // fallbacksrc={LandingPageImage}
            objectFit="cover"
            style={{ maxHeight: '225px' }}
          />
          <Box
            width={['100%']}
            height={['100%']}
            bgGradient={gradientColor}
            zIndex={100}
            position={'absolute'}
            bottom={0}
            display={'flex'}
            alignItems={'flex-end'}
            // opacity={[0.5]}
          >
            <Heading
              padding={[4]}
              paddingBottom={[3]}
              fontSize={fontSizes.cardHeading}
              color={fontHeadingColor}
              alignItems={['flex-end']}
              fontWeight={['medium']}
              textTransform={['capitalize']}
            >
              {index}. {name}
            </Heading>
          </Box>
        </Box>
        <Box padding={[4]}>
          <Text
            marginBottom={[3]}
            fontSize={fontSizes.cardDescription}
            noOfLines={fromIndexPage ? noOfLines : null}
          >
            {description}
          </Text>

          <RenderTypes types={types} />
          {noOfLines === NO_OF_LINES && <Text>...</Text>}

          <Flex
            marginTop={[3]}
            flexWrap={'wrap'}
            // alignSelf={'baseline'}
            justifyContent={
              fromIndexPage ? 'space-between' : 'flex-end'
            }
          >
            <Text
              display={fromIndexPage ? 'block' : 'none'}
              onClick={() => handleToggleNoOfLines()}
              fontSize={['sm']}
              fontWeight={['normal']}
              cursor={'pointer'}
              alignSelf={'flex-end'}
              width={'fit-content'}
              marginY={[0]}
              padding={[0]}
              borderRadius={[30]}
              backgroundColor={'transparent'}
              textDecor={'underline'}
              color={fontReadMoreColor}
            >
              {noOfLines === NO_OF_LINES ? 'Read More' : 'Read Less'}
            </Text>
            <Flex>
              <ButtonWithIcon
                iconName={IoLogoWhatsapp}
                bgColor={'whatsapp.500'}
                href={whatsappMessage}
              />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export const InsightsCard = ({ name, description, expandAll }) => {
  const NO_OF_LINES = 0
  const backgroundColor = useColorModeValue('brand.100', 'brand.900')
  const fontColor = useColorModeValue('brand.900', 'brand.1')
  const headerBackgroundColor = useColorModeValue(
    'brand.200',
    'brand.700'
  )
  const headingFontColor = useColorModeValue('brand.900', 'brand.1')
  const [noOfLines, setNoOfLines] = useState(NO_OF_LINES)
  const [showDescription, setShowDescription] = useState(false)
  const handleToggleNoOfLines = () => {
    if (noOfLines === NO_OF_LINES) {
      setNoOfLines(null)
    } else {
      setNoOfLines(NO_OF_LINES)
    }
  }
  const { isOpen, onToggle } = useDisclosure()

  const handleShowDescription = () => {
    setShowDescription(!showDescription)
    onToggle()
  }
  useEffect(() => {
    // handleShowDescription()
  }, [])
  return (
    <Box
      maxW={['100%', '100%', '100%', '100%']}
      marginY={['3%', '3%', '1%', '1%']}
      marginLeft={0}
      backgroundColor={backgroundColor}
      color={fontColor}
      borderRadius={[5]}
    >
      <Flex flexDirection="column" borderRadius={[5]}>
        <Flex
          justifyContent="space-between"
          alignItems={['center']}
          paddingX={[5]}
          paddingY={[5]}
          backgroundColor={headerBackgroundColor}
          borderRadius={[5]}
        >
          <Heading
            fontSize={fontSizes.cardHeading}
            color={headingFontColor}
            fontWeight={'medium'}
          >
            {name}
          </Heading>
          <Icon
            as={isOpen ? IoIosArrowUp : IoIosArrowDown}
            fontSize={fontSizes.cardHeading}
            color={headingFontColor}
            cursor={'pointer'}
            onClick={() => onToggle()}
          />
        </Flex>
        <Collapse in={isOpen} animateOpacity unmountOnExit>
          <Box
            paddingX={[5]}
            paddingBottom={[5]}
            // marginTop={[5]}
          >
            {description.map((text, index) => (
              <Text
                key={index}
                marginTop={[5]}
                fontSize={fontSizes.cardDescription}
                // noOfLines={[noOfLines]}
              >
                {text}
              </Text>
            ))}
          </Box>
        </Collapse>
      </Flex>
    </Box>
  )
}

export const Spacer = ({ id }) => {
  return (
    <Box
      id={id}
      width={['100%']}
      height={[5, 10, 10, 15]}
      marginY={[5]}
      marginX={['auto']}
    >
      {' '}
    </Box>
  )
}
