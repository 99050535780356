/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { Box } from '@chakra-ui/react'
// import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
// import Header from './header'
import NavBar from './NavBar'
import { Footer } from './Footer'
import { Button, Icon } from '@chakra-ui/react'
import { fontSizes } from './UtilityComponents'
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp'

const Layout = ({ children }) => {
  const [backToTopButton, setBackToTopButton] = useState(false)
  function scrollFunction() {
    if (typeof document !== `undefined`) {
      if (
        document.body.scrollTop > 40 ||
        document.documentElement.scrollTop > 40
      ) {
        setBackToTopButton(true)
      } else {
        setBackToTopButton(false)
      }
    }
  }

  const windowDef = typeof window !== undefined

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    if (typeof document !== `undefined`) {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }
  }
  useEffect(() => {
    if (windowDef) {
      window.onscroll = () => scrollFunction()
    }
  }, [])
  return (
    <>
      <Box position={['relative']}>
        {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
        <NavBar />
        <Box as="div" margin="0 auto" padding="0" width={'100%'}>
          <Box as="main">{children}</Box>
        </Box>
        <Footer />
        <Box
          as="footer"
          backgroundColor="brand.800"
          fontSize="l"
          width="100%"
          textAlign="center"
          color="brand.50"
          paddingY="1.5"
        >
          © {new Date().getFullYear()} Aishwarya Agarwal & Co.
        </Box>
        <Button
          display={backToTopButton ? 'block' : 'none'}
          onClick={() => topFunction()}
          fontWeight={['normal']}
          alignSelf={'flex-end'}
          width={'fit-content'}
          // height={'fit-content'}
          padding={[0]}
          borderRadius={[50]}
          backgroundColor={'purple.500'}
          justifyContent={'center'}
          textAlign={'center'}
          boxShadow={['md']}
          position={'fixed'}
          bottom={[5]}
          right={[5]}
          zIndex={[200]}
          alignContent={['center']}
        >
          <Icon
            as={IoIosArrowUp}
            fontSize={fontSizes.actionButtonIcons}
            color={'white'}
            cursor={'pointer'}
            padding={[0]}
            // onClick={() => onToggle()}
          />
        </Button>
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
