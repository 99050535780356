export const ServicesContent = [
  {
    name: 'Start-up Services',
    description:
      'We support the Start-up India initiative that aims to accelerate entrepreneurship in the country and create start-ups by providing personalised guidance regarding all the benefits of start-up registration. We guide you through the whole process from Selecting a right name of your company, Drafting the Constitution of the Company (MOA & AOA), Certificate of commencement of Business to all the Accounting and Auditing services along with the complete guidance of Taxation benefits.',
    types: {
      multiType: false,
      typesList: [
        'Start-up registration',
        'Name approval of the company',
        'Angel Exemption',
        'Sec 80-IAC Exemption',
        'ESOP Policy',
        'Pitch Deck',
        'Investment and Strategy Advisory',
        'Availing all the Start-up benefits and all the other services related to Start-up',
      ],
    },
    imageName: require('./images/Startup.jpg'),
  },
  {
    name:
      'Advisory Services to Foreign Start-ups who wish to incorporate in India',
    description:
      'There are stringent compliances for foreign nationals to Incorporate Start-up in India. In order to ease the business operations we provide all the Advisory Services from Incorporation of the Company to giving a complete advice and guidance on all the business and compliance activities.',

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Company Structuring',
            'Transfer Pricing Provisions',
            'Intellectual Property Rights',
            'Monthly Financials',
            'ROC Compliances',
            // 'Internal Audit',
          ],
        },
        {
          name: '',
          list: [
            'FEMA Regulations',
            'GST Refunds',
            'Budgeting',
            'IFRS',
            'Compliances Advisory',
          ],
        },
      ],
    },
    imageName: require('./images/ForeignStartuptoIndia.jpg'),
  },
  {
    name: 'Fund Raising Advisory Services for Start-Ups',
    description:
      'Fund Raising advisory services includes analysing how to get funds from the desired Investors considering all the tax benefits permitted within the tax regulatory framework.',

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Funding Services',
            'Valuation services',
            'Negotiation with Investors',
            'Instrument Selection',
            'Due Diligence (Financial and Legal)',
            'Agreement Drafting (SHA/SSA)',
            'License from RBI, SEBI, IRDA',
            'Treasury Management',
          ],
        },
      ],
    },
    imageName: require('./images/FundRaising.jpg'),
  },
  {
    name: 'Auditing and Assurance services',
    description:
      'Auditing services assist companies with constructing compliant financial statements and communication with banks, investors/shareholders, and other financial partners that their finances are in order. With our risk-oriented approach and as modern auditors, we offer you more than merely auditing the correctness of your accounts. We offer comprehensive, high-quality and forward-looking audit services. It includes services such as',

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Audit under Income Tax Act',
            'Audit under Companies Act',
            'Compliance Audit',
            'Financial Statement Audit',
            'Information Technology Audit',
            'Internal Audit',
          ],
        },
        {
          name: '',
          list: [
            'Statutory Audit',
            'Follow up Audit',
            'Integrated Audits',
            'Concurrent Audit',
            'Department Audit',
            'Payroll Audit',
          ],
        },
        {
          name: '',
          list: [
            'Investigative Audit',
            'Management Audit',
            'Operational Audit',
            'Performance Audit',
            'Revenue Audit',
            'SOX Audit',
            'Inventory Audit',
          ],
        },
      ],
    },
    imageName: require('./images/AuditandAssurance.jpg'),
  },

  {
    name: 'Corporate Secretarial Services',
    description:
      'With an intent to bring transparency and increased governance in working of companies in India and to weed out the menace of black money, the Corporate Laws are witnessing changes at a rapid speed. The exponential growth in the level of compliance coupled with stringent monitoring by regulatory agencies, are forcing corporate to become more compliant and vigilant in their dealing. Such development exposes both the Company and its Directors to a higher regulatory risk, if the importance of compliance is not recognized. \nOur focused and expert Corporate Secretarial Team offers wide array of customized solutions to meet your various needs to manage and mitigate risks of corporate non-compliance.',
    types: {
      multiType: false,
      typesList: [
        'Company registration',
        'Name approval of the company',
        'Certificate of Incorporation',
        'Board compliances',
        'Issuing of Share certificate and transfer of shares',
        'FEMA compliances wherever applicable',
        'Filing of MGT-7 and AOC-4 and all other secretarial compliances',
        'All other ROC Filings',
      ],
    },
    imageName: require('./images/CorporateSecretarial.jpg'),
  },
  {
    name: 'Goods and Service Tax (GST)',
    description:
      'GST is a single, destination based indirect tax levied on the value added to goods as well as services at each stage of the supply chain. The main objective behind levying such a tax is to consolidate multiple indirect tax levies into a single tax. Thus, GST subsumes a host of taxes. GST is the all-in-one tax that covers all businesses.',

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'GST Registration',
            'GST Invoice generation',
            'GSTR-9 (Annual Return)',
            'GSTR-9C (Audit report)',
            'GSTR-3B Filing',
            'GSTR-1 Filing',
            'Letter of Undertaking',
          ],
        },
        {
          name: '',
          list: [
            'GST Registration for Foreigners',
            'E-Way Bill',
            'GST E-Invoicing',
            'Cancellation of GST Registration',
            'GST Refunds',
            'GST TDS Compliances',
          ],
        },
        {
          name: '',
          list: [
            'GSTR-2A and GSTR-1 Reconciliation',
            'CMP-08(GST Filings for Composition dealers)',
            'Temporary GST Registration',
            'GST Notices and Assessment',
            'GST Appeals',
          ],
        },
      ],
    },
    imageName: require('./images/GST.jpg'),
  },
  {
    name: 'Tax Advisory Services',
    description:
      'Tax advisory service includes analysing financial and tax problems, formulating solutions and making recommendations designed to provide advice on taxation for clients ranging from individuals to business. We provide the Tax Advisory and Tax planning Services to Individuals, Partnership firm, LLP, Private Limited Company and to every other business. It includes services such as',
    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Taxation planning',
            // 'Income Tax registration',
            'Tax Refunds',
            'Lower Tax Deduction Certificate',
            'Statutory Compliances',
            'Availing all the Income Tax benefits and all such services',
          ],
        },
      ],
    },
    imageName: require('./images/taxadvisory.jpg'),
  },
  {
    name: 'Income Tax Services',
    description:
      'Income tax is levied on the income of a person/ assessee at specified rates and is paid directly to the Central government. We prepare the financial statements (ie, Profit and Loss Statement, Balance sheet and Cash Flow Statement). Income Tax returns should be filed within the due dates with proper knowledge to avoid Penalties and we also appear for Appeals and scrutiny Assessments.',
    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Income Tax Return Filing',
            'Statutory Compliances',
            'Income Tax Appeals',
            // 'Income Tax Notice',
            'Assessment Compliances',
            'Sec 12AA (Application for Trust Registration)',
          ],
        },
      ],
    },
    imageName: require('./images/IncomeTax.jpg'),
  },

  {
    name: 'TDS and TCS Returns',
    description:
      'An employer, businessman or a company needs to apply for TAN registration to deduct TDS when they make payments which exceed a particular limit as prescribed in the Income Tax Act.      ',

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'TAN Registration',
            'TDS Return filing',
            'TCS Return filing and Compliances',
            'TDS Notices and Assessments',
            'TDS Refunds',
            'Form-16 and Form-16A Issuance',
            // 'License from RBI, SEBI, IRDA',
            // 'Treasury Management',
          ],
        },
      ],
    },
    imageName: require('./images/TDS.jpg'),
  },
  {
    name: 'Banking Finance Services',
    description:
      'Finance is the main resource for the survival of every business . In order to cater the financial needs of the business, we provide the Finance Services through which you get the capital and can expand your business.',

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Arranging loans from Financial Institution',
            'Preparation of Projection Report',
            'CMA Data',
            'Loan Appraisal',
            'Loan approval documentation',
            'Valuation Reports',
            'Sanction of Loan',
            'All other services till the Disbursement of Loan',
          ],
        },
      ],
    },
    imageName: require('./images/BANKINGFINANCE.jpg'),
  },

  {
    name: 'Virtual CFO Services',
    description:
      'Virtual CFO Service includes analysing Business, Compliance, Statutory, Financial and Tax problems, formulating solutions and making recommendations designed to provide advice on all such matters. It involves from setting up the business to advising on all the business operations.',

    types: {
      multiType: true,
      typesList: [
        {
          name: 'Tax & Finance',
          list: [
            'Tax Compliance',
            'Audit and Assurance',
            'Handling Regulators',
            'Tax Planning and Saving',
            'Policy Documentation',
            'Financial Modelling',
            // 'All other services till the Disbursement of Loan',
          ],
        },
        {
          name: 'Strategies',
          list: [
            'Selection of Right Set of Investors',
            'Acquisition',
            'Go to Market Strategies',
            'Company Structure',
            'Business Terms/ Models',
            //  '',
          ],
        },
        {
          name: 'Resource Management',
          list: [
            'Hiring vs Outsourcing',
            'Wealth Management',
            'Budgeting',
            'Variance Analysis',
            'Resource Augmentation Service',
          ],
        },
      ],
    },
    imageName: require('./images/VirtualCFO.jpg'),
  },
  {
    name: 'Trade Mark Registration',
    description:
      "Trademark Registration is very essential these days. Advantages of Trademark Registration includes Exclusive Rights, Builds Trust and Goodwill, Differentiates Product, Recognition to product's quality, Creation of Asset, Use of ® symbol, Protection against infringement, Protection for 10 Years at low cost.",

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Trademark Registration',
            'Trademark Objection',
            'Trademark Opposition',
            'Trademark Renewal ',
            'Find trademark class for over 8000 goods and services',
          ],
        },
      ],
    },
    imageName: require('./images/Trademark.jpg'),
  },
  {
    name: 'MSME (Udyog Aadhar Registration)',
    description:
      "MSME stands for Micro, Small and Medium Enterprises. In a developing country like India, MSME industries are the backbone of the economy. These industries are also known as small-scale industries or SSI's. MSME registration comes with many advantages such as cheaper loans, lower projection costs, preference for Government tenders and many other benefits of governmental schemes.",

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: ['MSME Registration', 'Availing MSME benefits'],
        },
      ],
    },
    imageName: require('./images/MSME.jpg'),
  },

  {
    name: 'Certification Services',
    description:
      'There are few documental submissions which require a mandatory CA certification. We provide all types of CA certifications after analyzing the documents.',

    types: {
      multiType: false,
      typesList: [
        'Net worth Certificate',
        'Turnover Certificate',
        'Income Certificate',
        '15CB Certificate',
        'Form 29 B Certificate',
        'MCA Filing certificate',
        'And few more Certifications',
      ],
    },
    imageName: require('./images/Certification.jpg'),
  },
  {
    name: 'Business Process Outsourcing and Accounting Services',
    description:
      "Our key focus areas are to cater transformational value to our client's viz., process excellence-automation, re-engineering, internal/ external benchmarking and variable pricing models. The services can also include payroll, accounting, telemarketing, data recording.",
    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Accounting',
            'Business Plan',
            'Business Valuation',
            'Strategic Planning',
          ],
        },
        {
          name: '',
          list: [
            'Compliance',
            'Outsourcing',
            'Due Diligence',
            'Feasibility Assessment',
            // '',
            //  '',
          ],
        },
        {
          name: '',
          list: [
            'Comparitive Analysis of Financial Reports',
            'Internal Restructuring Consultancy',
            'Organisation Assessment Survey',
            // '',
            // '',
            //  '',
          ],
        },
      ],
    },
    imageName: require('./images/landingImg.jpg'),
  },
  {
    name: 'Registration Services',
    description:
      'As the compliance and transparency is being stringent these days, the registration is always advisable to avail the benefits.',

    types: {
      multiType: true,
      typesList: [
        {
          name: '',
          list: [
            'Professional Tax Registration',
            'Digital Signature',
            'Import Export Code',
            'FSSAI Registration',
            'Trade License',
            'RERA',
          ],
        },
        {
          name: '',
          list: [
            'LLP',
            'AOP/Trust',
            'Co-operative Society',
            'Nidhi Company',
            'Producer Company',
            'Section 8 Company',
            'Private Limited Company',
          ],
        },
        {
          name: '',
          list: [
            'Copyright Registration',
            'Design Registration',
            'Provisional Patent',
            'Patent Registration',
            //  '',
          ],
        },
      ],
    },
    imageName: require('./images/Registration.png'),
  },
  {
    name: 'Compliance Services',
    description:
      'Every business needs to comply with all the small regulations also, which are generally not taken into consideration while running the business but can result in huge penalties. We provide every compliance service required to run the business effectively.',

    types: {
      multiType: true,
      typesList: [
        {
          name: 'Payroll Compliance',
          list: [
            'Payroll',
            'Pay Slips',
            'PF Registration',
            'PF Return Filing',
            'ESI Registration',
            'ESI Return Filing',
          ],
        },
        {
          name: 'Other Corporate Compliance',
          list: [
            'Registered Office Change',
            'Addition of Directors',
            'Removal of Directors',
            'Share Transfer',
            'MOA Amendment',
            'Winding Up of Company',
            'Winding Up of LLP',
            'Increase Authorized Capital',
          ],
        },
      ],
    },
    imageName: require('./images/Compliance.jpg'),
  },
  {
    name: 'Documentation And Support Services',
    description:
      'Documentation is a crucial part to record information that can help support the proper plan and the reasoning for such services. Documents serve best for future references and discussions.',

    types: {
      multiType: true,
      typesList: [
        {
          name: 'Corporate',
          list: [
            'Partnership Deed',
            'Drafting of Board Resolutions',
            // 'PF Registration',
            // ' PF Return Filing',
            // 'ESI Registration',
          ],
        },
        {
          name: 'Real Estate',
          list: [
            'Lease Deed-Commercial Property',
            'Notice to Vacate Tenant',
            ' Lease Deed - Residential Property',
            // ' Share Transfer',
          ],
        },
        {
          name: 'Employment',
          list: [
            'Offer Letter',
            'Appointment Letter',
            'Resignation Letter',
            // 'Increase Authorized Capital',
          ],
        },
      ],
    },
    imageName: require('./images/Documentation.jpg'),
  },
]

// const tempStrut = {
//   name: '',
//   list: [
//     '',
//     '',
//     '',
//     '',
//     '',
//    '',
//   ],
// },

export const InsightsContent = [
  {
    name: 'Sole Proprietorship',
    description: [
      "It is also referred to as a sole trader or a proprietorship, it is an unincorporated business that has just one owner who pays personal income tax on profits earned from the business. Many sole proprietors do business under their own names because creating a separate business or trade name isn't necessary. However, with sole proprietorship, your business is legally indistinguishable from yourself, which means that your personal assets are at risk if you are liable for your business activities.",
    ],
  },
  {
    name: 'Partnership Firm',
    description: [
      'A business structure in which two or more individuals manage a business per the terms in Partnership Deed. It’s best suitable for home businesses that are unlikely to take any debt due to low cost, ease of setting up and minimal compliance requirements.',
    ],
  },
  {
    name: 'Limited Liability Partnership',
    description: [
      'LLP was introduced to provide a form of business that is easy to maintain and to help owners by providing them with limited liability. Limited Liability Partnership Registration combines the benefits of a partnership with that of a limited liability company.',
    ],
  },
  {
    name: 'Private Limited Company',
    description: [
      'The default option for start-ups and growing businesses as only private limited companies can raise venture capital. This type of company offers limited liability for its shareholders with certain restrictions placed on the ownership. Private limited company registration, directors may be different from shareholders.',
    ],
  },
  {
    name: 'Public Limited Company',
    description: [
      "A public limited company is a voluntary association of members that are incorporated and, therefore has a separate legal existence and the liability of whose members is limited. Public limited companies are listed on the stock exchange where it's share/stocks are traded publicly.",
    ],
  },
  {
    name: 'One Person Company',
    description: [
      'The best structure for solo entrepreneurs looking beyond the opportunities a sole proprietorship affords. Here, a single promoter gains full authority over the company, restricting his/her liability towards their contributions to the enterprise. The said person will be the sole shareholder and director.',
    ],
  },
  {
    name: 'Producer Company',
    description: [
      'A Producer is any person engaged in any close-knit or related activity to the primary producers. A Producer Company is thus an umbrella term that includes all of the following. Producing, harvesting, procurement, grading, pooling, handling, marketing, selling, exporting the primary producers of the members, or the imports of goods or services.',
    ],
  },
  {
    name: 'Section 8 Company',
    description: [
      'The primary purpose of registering a company as a Section 8 Company is to promote non-profit objectives such as trade, commerce, arts, charity, education, religion, environment protection, social welfare, sports research, etc.',
      'The profits of this company, if there are any, are applied towards promoting the objectives of the company and not distributed as dividends to its shareholders. As per the new Companies Act of 2013, Section 25 has now become Section 8. Also, there is no requirement of minimum paid-up capital in the case of Section 8 Company.',
    ],
  },
]
