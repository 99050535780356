import React from 'react'
import {
  Link,
  Box,
  Flex,
  Text,
  Stack,
  useColorModeValue,
  Image,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import ThemeToggle from './theme-toggle'
import CALogo from '../images/ca-logo.png'
const NavBar = props => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <NavBarContainer {...props}>
      <Logo
      // w="100px"
      // color={['white', 'white', 'brand.500', 'brand.500']}
      />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks toggle={toggle} isOpen={isOpen} />
    </NavBarContainer>
  )
}

const Logo = props => {
  return (
    <Box
      {...props}
      marginLeft={[5, 5, 0]}
      paddingY={[3, 3, 0]}
      display={['flex']}
      alignItems={['center']}
      width={['fit-content']}
      borderColor={['white']}
      borderWidth={[0]}
    >
      {/* <Text fontSize="lg" fontWeight="bold"> */}

      <Link
        as={GatsbyLink}
        to="/"
        color="white"
        _hover={{ textDecor: 'none' }}
        // fontSize="2xl"
        fontWeight="medium"
        flexDirection="column"
        textTransform={['uppercase']}
        justifyContent={'center'}
      >
        <Flex
          width={['fit-content']}
          alignItems={['center']}
          borderColor={['white']}
          borderWidth={[0]}
        >
          <Flex
            flexDirection={['column']}
            // justifyContent={['center']}
            marginRight={[2]}
          >
            <Text
              textAlign={['center']}
              fontSize={['lg']}
              letterSpacing="0.1em"
            >
              {' '}
              Aishwarya
            </Text>{' '}
            <Text fontSize={['md']} letterSpacing="0em">
              Agarwal & Co.
            </Text>
          </Flex>
          <Image
            width={['54px']}
            backgroundColor={['white']}
            padding={[1.5]}
            borderRadius={[200]}
            src={CALogo}
            fontSize={'xs'}
            alt={'CA-logo'}
          />
        </Flex>
        <Text
          fontSize={['xs']}
          textAlign={['center']}
          letterSpacing=".075em"
          color={['cyan.50']}
        >
          Chartered Accountants
        </Text>
      </Link>
    </Box>
  )
}

const CloseIcon = () => (
  <svg
    width="24"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
)

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
)

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box
      display={{ sm: 'block', md: 'none' }}
      onClick={toggle}
      marginRight={[5, 5, 0]}
    >
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  )
}

const MenuItem = ({
  toggle,
  children,
  isLast,
  to = '/',
  ...rest
}) => {
  return (
    <Link
      href={to}
      paddingX={[3]}
      paddingY={[2]}
      borderRadius={[5]}
      _hover={{ backgroundColor: 'brand.500' }}
      textDecoration={'none'}
      _active={{ backgroundColor: 'brand.600' }}
      onClick={toggle}
    >
      <Text display="block" {...rest} fontWeight="medium">
        {children}
      </Text>
    </Link>
  )
}

const MenuLinks = ({ toggle, isOpen }) => {
  return (
    <Box
      backgroundColor={[
        'brand.500',
        'brand.500',
        'transparent',

        'transparent',
      ]}
      display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
      flexBasis={{ base: '100%', md: 'auto' }}
      // marginTop={[5, 5, 0]}
      paddingBottom={[3, 3, 0]}
    >
      <Stack
        spacing={8}
        align="center"
        justify={['center', 'center', 'flex-end', 'flex-end']}
        direction={['column', 'center', 'row', 'row']}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem toggle={toggle} to="/#about-us">
          About Us
        </MenuItem>
        <MenuItem toggle={toggle} to="/services">
          Services
        </MenuItem>
        <MenuItem toggle={toggle} to="/insights">
          Insights{' '}
        </MenuItem>
        {/* <MenuItem to="/page-2">Blogs</MenuItem> */}
        <MenuItem toggle={toggle} to="/#contact-us">
          Contact Us
        </MenuItem>
        <Box as="div" position="relative">
          <ThemeToggle onClick={toggle} />
        </Box>
      </Stack>
    </Box>
  )
}

const NavBarContainer = ({ children, ...props }) => {
  const navigationBarBackgroundColor = useColorModeValue(
    'linear(to-bl, brand.300, brand.400)',
    'linear(to-br, brand.400, brand.500)'
  )
  return (
    <Box w={'100%'} bgGradient={navigationBarBackgroundColor}>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        maxW="1400px"
        marginBottom={20}
        margin={'auto'}
        paddingX={[0, 0, 5]}
        paddingY={[0, 0, '1em']}
        // paddingTop={5}
        color={'white'}
        {...props}
      >
        {children}
      </Flex>
    </Box>
  )
}

export default NavBar
